import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({ providedIn: 'root' })
export class CdeGtmService {
  constructor(private gtmService: GoogleTagManagerService) {}

  pushTag(item: object) {
    this.gtmService.pushTag(item);
  }
}
